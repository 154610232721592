import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import {
  HeroBlock,
  Decoration,
  RecipeCard,
  SEO,
  NewsletterWithDownload,
  Space,
} from '../components/shared'
import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  colors,
  fonts,
} from '../styles'
import { Image } from '../components/shared/Image'
import { useLocale, useNewsletterInactive } from '../hooks'

const ArticleCollection = ({
  location,
  data: {
    contentfulPageArticleCollection,
    contentfulGlobalSite,
    allContentfulPageRecipe,
  },
}) => {
  const {
    heroblock,
    title,
    articles,
    thumbnailImage,
    tags,
    description,
    video,
    seoDescription,
    recipeTags,
    seoTitle,
    veganuaryActive,
    veganuaryPopupImage,
  } = contentfulPageArticleCollection

  const {
    decorationRecipePageBottomMobile,
    decorationRecipePageBottomDesktop,
    blackBrushesAllRecipePages,
    globalNewsletterActiveOnVegan,
  } = contentfulGlobalSite

  const [showAll, setAll] = useState(false)

  const locale = useLocale()

  const newsletterInactive = useNewsletterInactive()

  const localeRecipes = []

  allContentfulPageRecipe.edges.map((recipe) => {
    if (recipe.node_locale === locale) {
      localeRecipes.push(recipe)
    }
  })

  const checkRecipeTags =
    recipeTags &&
    localeRecipes.filter(({ node }) => {
      const arr = [
        ...(node?.dietaryTags || []),
        ...(node?.courseTag || []),
        ...(node?.occasionTags || []),
        ...(node?.seasonText || []),
        ...(node?.difficultyLevel || []),
        ...(node?.allergyLabels || []),
        ...(node?.specialEvent || []),
      ]

      if (recipeTags && arr)
        return recipeTags.some((string) =>
          arr.toString().toLowerCase().includes(string.toLowerCase()),
        )
    })

  const noDuplicates = [
    ...(recipeTags ? checkRecipeTags.map((item) => item.node) : []),
    ...(articles || []),
  ].reduce((unique, o) => {
    if (!unique.some((obj) => obj.title === o.title)) {
      unique.push(o)
    }
    return unique
  }, [])

  useEffect(() => {
    if (!globalNewsletterActiveOnVegan) {
      newsletterInactive()
    }
  }, [])

  const raw = JSON.stringify({
    content: [
      {
        content: [
          {
            data: {},
            marks: [],
            value: description,
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
  })
  return (
    <ArticleCollectionPageWrapper>
      <SEO
        description={seoDescription}
        title={seoTitle}
        location={location}
        pageType='Article collection'
      />
      <HeroWrapper>
        <HeroBlock
          gold
          marginTitle={[60]}
          addSpace
          fontSize={60}
          data={{
            ...heroblock,
            imagesSlider: [thumbnailImage],
            video,
            marginDescription: ['-50', '55'],
            richDescription: {raw},
          }}
        />
      </HeroWrapper>

      <StyledDecoration>
        {blackBrushesAllRecipePages &&
          blackBrushesAllRecipePages.map((item, i) => {
            return <Decoration key={i} data={item} />
          })}
      </StyledDecoration>
      {(location.pathname === '/fr/recipe/vegan/' ||
        location.pathname === '/fr/recipe/vegan') &&
        veganuaryActive && (
          <>
            <NewsletterWithDownload popupImage={veganuaryPopupImage} />
            <Space l={2} s={1} />
          </>
        )}
      <Wrapper>
        <StyledTitle>
          {title.split(' ').map((word, i) => (
            <span key={i}>{word}</span>
          ))}
        </StyledTitle>
        <Articles>
          {noDuplicates &&
            noDuplicates.map((recipe, i) =>
              showAll ? (
                <RecipeCard
                  key={recipe.slug}
                  slug={recipe.slug}
                  thumbnailImage={recipe.thumbnailImage}
                  title={recipe.title}
                />
              ) : (
                i <= 5 && (
                  <RecipeCard
                    key={recipe.slug}
                    slug={recipe.slug}
                    thumbnailImage={recipe.thumbnailImage}
                    title={recipe.title}
                  />
                )
              ),
            )}
        </Articles>
      </Wrapper>
      <StyledButton
        disappear={(noDuplicates && noDuplicates.length <= 6) || showAll}
        onClick={() => setAll(!showAll)}
      >
        Voir plus d'articles
      </StyledButton>
      <BottomDecorationWrapper>
        <MobileWrapper>
          <Image
            fluid={decorationRecipePageBottomMobile.image.fluid}
            alt={decorationRecipePageBottomMobile.image.title}
          />
        </MobileWrapper>
        <DesktopWrapper>
          <Image
            fluid={decorationRecipePageBottomDesktop.image.fluid}
            alt={decorationRecipePageBottomDesktop.image.title}
          />
        </DesktopWrapper>
      </BottomDecorationWrapper>
    </ArticleCollectionPageWrapper>
  )
}
const HeroWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    h1 {
      padding-right: ${desktopVw(40)};
    }
  }
`

const ArticleCollectionPageWrapper = styled.div`
  overflow-x: hidden;
`

const Wrapper = styled.div`
  margin: ${mobileVw(35)} ${mobileVw(16)} ${mobileVw(0)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 ${desktopVw(128)} ${desktopVw(0)};
  }
`

const StyledTitle = styled.h2`
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(23)};
  color: ${colors.black};
  font-family: ${fonts.splandor};
  width: ${mobileVw(175)};
  margin: 0 0 ${mobileVw(10)} 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  span {
    padding-right: ${mobileVw(5)};
    &:first-child {
      color: ${colors.mailleGold};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(44)};
    line-height: ${desktopVw(32)};
    margin: 0 0 ${desktopVw(20)} 0;

    span {
      padding-right: ${desktopVw(8)};
    }
  }
`

const StyledButton = styled.button`
  width: ${mobileVw(288)};
  background-color: ${colors.mailleGold};
  padding: ${mobileVw(15)} 0 ${mobileVw(14)};
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(20)};
  color: ${colors.white};
  text-transform: uppercase;
  letter-spacing: ${mobileVw(2)};
  line-height: ${mobileVw(20)};
  display: block;
  margin: 0 auto ${mobileVw(40)};
  z-index: 1;
  display: ${(props) => (props.disappear ? `none` : `block`)};
  cursor: pointer;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(350)};
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: ${desktopVw(16)};
    margin: ${desktopVw(25)} auto ${desktopVw(40)};
  }
`

const Articles = styled.div`
  display: block;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${desktopVw(80)} ${desktopVw(30)};
  }
`

const BottomDecorationWrapper = styled.div`
  width: 100%;
`

const MobileWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(900)};
    display: block;
    margin-left: ${desktopVw(450)};
    margin-top: ${desktopVw(75)};
  }
`

const StyledDecoration = styled.div`
  position: relative;
  background: #000;
  z-index: -1;
  margin-bottom: ${desktopVw(50)};
  padding-top: ${mobileVw(40)};
  margin-bottom: ${mobileVw(15)};

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: ${desktopVw(80)};
    margin-bottom: ${desktopVw(110)};
  }
`

export const Articlecollection = graphql`
  query articlecollectionQuery($id: String!, $node_locale: String!) {
    allContentfulPageRecipe {
      edges {
        node {
          node_locale
          id
          slug
          courseTag
          dietaryTags
          occasionTags
          seasonText
          difficultyLevel
          allergyLabels
          specialEvent
          courseTag
          occasionTags
          title
          thumbnailImage {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    contentfulPageArticleCollection(id: { eq: $id }) {
      seoDescription
      # recipeTags
      seoTitle
      title
      # tags
      description
      thumbnailImage {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      articles {
        title
        slug
        thumbnailImage {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      # video {
      #   name
      #   poster {
      #     fluid {
      #       src
      #     }
      #   }
      #   videoMov {
      #     file {
      #       url
      #     }
      #   }
      #   videoMp4 {
      #     file {
      #       url
      #     }
      #   }
      # }
      heroblock {
        title
        marginDescription
        marginBottom
        background
        breadcrumbsTitle
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        richDescription {
            raw
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decoration {
          image {
            fluid(maxWidth: 2400) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
      # veganuaryActive
      # veganuaryPopupImage {
      #   title
      #   fluid(sizes: "(max-width: 1023px) 100vw, 40vw") {
      #     ...GatsbyContentfulFluid_withWebp_noBase64
      #   }
      # }
    }
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      node_locale
      globalNewsletterActiveOnVegan
      blackBrushesAllRecipePages {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      decorationRecipePageBottomMobile {
        name
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      decorationRecipePageBottomDesktop {
        name
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
    }
  }
`

export default ArticleCollection
